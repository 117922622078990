import { useState } from "react";
import { supabase } from "../../supabaseClient";
import "./signIn.css";

const SignIn = ({ closeModal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) setError(error.message);
    else {
      alert("Signed in successfully");
      closeModal();
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError("Please enter your email to reset your password.");
      return;
    }
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) setError(error.message);
    else setMessage("Check your email for the password reset link.");
  };

  return (
    <div className="sign-in-modal">
      <div className="sign-in-modal-content">
        <button className="close-button" onClick={closeModal}>
          ×
        </button>
        <h2 className="modal-title">Log in to your account</h2>
        <form onSubmit={handleSignIn} className="sign-in-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit">Sign In</button>
          {error && <p className="error-text">{error}</p>}
          {message && <p className="success-text">{message}</p>}
        </form>
        <button className="forgot-password" onClick={handlePasswordReset}>
          Forgot password?
        </button>
      </div>
    </div>
  );
};

export default SignIn;
